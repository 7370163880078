@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Moon Flower Bold;
    src: url("../fonts/Moon-Flower-Bold.ttf") format("woff");
}

@font-face {
    font-family: Southern Jannie;
    src: url("../fonts/Southern-Jannie.ttf") format("woff");
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap');;
}

@layer utilities {
    .clip {
        clip-path: inset(0 0 70px 0)
    }
}

html {
    min-height: 100vh;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    /* mobile viewport bug fix */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
    font-family: Montserrat, sans-serif;
}
.menu-vertical {
    @apply
    w-1/3
    flex
    flex-col
    space-y-5
    text-center
}

.menu-horizontal {
    @apply
    w-1/2
    flex
    space-x-5
    justify-center
}

.neon {
    position: relative;
    box-shadow: 0 0 10px #F2E344,
    0 0 14px #eaca0b,
    0 0 15px #eddb08
}

.neon-blue {
    position: relative;
    box-shadow: 0 0 10px #7CD3FE,
    0 0 14px #5DBAF5,
    0 0 15px #5DBAF5
}

.anchor {
    @apply
    w-6
    h-6
    border border-2 border-white
    rounded-full
}

.anchor.active {
    @apply
    bg-white
}

.swiper-pagination-bullet {
    @apply
    w-3 md:w-4
    h-3 md:h-4
    xl:-translate-x-5
    translate-y-10
    bg-transparent
    border-2 border-white
    opacity-100
    md:translate-y-12
}

.swiper-pagination-bullet-active {
    @apply
    bg-white
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
